var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"address-main"},[_c('pageBread',{attrs:{"text":_vm.pageTitle,"img":function () { return require('../../assets/image/address-pageIcon.png'); }}}),_c('br'),_c('nqForm',{attrs:{"submit-text":_vm.submitText,"cancel-text":"取消","values":_vm.formValue,"form-list":[
      {
        label: '目的地',
        key: 'nationId',
        type: 'countrySelect',
        placeholder: '请选择国家',
      },
      {
        label: '城市',
        key: 'city',
        type: 'input',
        placeholder: '请输入城市',
      },
      {
        label: '邮编',
        key: 'post',
        type: 'input',
        placeholder: '请输入邮编',
      },
      {
        label: '收件人',
        key: 'recipient',
        type: 'input',
        placeholder: '请输入详收件人全名，请勿简写',
      },
      {
        label: '联系电话',
        key: 'tel',
        type: 'input',
        placeholder: '请输入联系电话',
      },
      {
        label: '详细地址',
        key: 'detailAddress',
        type: 'input',
        maxlength: '1000000000',
        placeholder: 'P.O,BOX邮箱地址无法派送请填写详细地址(部分中东地区)',
      },
      {
        label: '公司名',
        key: 'company',
        type: 'input',
        placeholder: '如果是私人快件，此处填写收件人姓名',
      },
      {
        label: '电子邮箱',
        key: 'email',
        type: 'input',
        placeholder: '选填，请输入收件人邮箱',
        norequire: true,
      },
      {
        label: '收件人增值税或其他税号',
        key: 'taxNote',
        type: 'input',
        placeholder: '选填',
        norequire: true,
      } ]},on:{"submit":_vm.submitForm,"cancel":_vm.cancelForm}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }